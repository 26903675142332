
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import PageTitle from '@/components/common/PageTitle.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import ExpertGroupList from '@/components/assessmentChecklist/ExpertGroupList/ExpertGroupList.vue';
import TimeToEndOfStage from '@/components/assessmentChecklist/TimeToEndOfStage.vue';
import { V1EntitiesExpertListsPublicIndex } from '@/services/api/tsxass';
import { SurveyStageType } from '@/constants/surveyStage';

export default Vue.extend({
  name: 'AssessmentExpertSelection',

  components: {
    PageTitle,
    SectionTitle,
    ExpertGroupList,
    TimeToEndOfStage,
  },

  data() {
    return {
      expertsChosen: null as null | boolean,
    };
  },

  computed: {
    ...mapState('survey', {
      surveyMeta: 'meta',
    }),
    ...mapGetters('survey', {
      surveyStageChoosingExperts: 'surveyStageChoosingExperts',
    }),

    isBigScreen(): boolean {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    surveyId(): number {
      return Number(this.$route.params.surveyId);
    },
    stageDateEnd(): string | null {
      return this.surveyStageChoosingExperts?.dateEnd;
    },
    stageIsCompleted(): boolean {
      return !!this.stageDateEnd && this.$dayjs(this.stageDateEnd).isBefore(this.$dayjs());
    },
    stageInProgress(): boolean {
      const isCurrentStage = !!this.surveyStageChoosingExperts
        && this.surveyStageChoosingExperts.type === SurveyStageType.CURRENT;
      return !this.stageIsCompleted && isCurrentStage;
    },
  },

  methods: {
    onCouplesLoad(couplesData: V1EntitiesExpertListsPublicIndex) {
      if (this.expertsChosen !== null) {
        return;
      }

      this.expertsChosen = !!couplesData.couples.length;
    },
  },
});
