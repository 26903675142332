
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import errorHandler from '@/helpers/errorHandler';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesExpertListsPublicIndexExpertList,
  V1EntitiesExpertListsPublicIndexCouple,
  PostV1CouplesIam,
  PostV1Invitees,
} from '@/services/api/tsxass';
import { ExpertGroupCouples, joinExpertListsWithCouples } from '@/helpers/joinExpertGroupsWithCouples';
import { UserId } from '@/services/api/common/types';
import TTLoader from '@/components/ui/TTLoader.vue';
import ExpertGroupUserAddingDialog from '@/components/userAddingDialogs/ExpertGroupUserAddingDialog.vue';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';
import ExpertGroup from './ExpertGroup.vue';

export default Vue.extend({
  name: 'ExpertGroupList',

  components: {
    ExpertGroup,
    TTLoader,
    ExpertGroupUserAddingDialog,
  },

  props: {
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      default(): UserCardColumn[] {
        return [
          {
            name: UserCardColumnNames.EMPLOYEE,
            title: this.$t('userForRate.employee') as string,
            width: '6fr',
          },
          {
            name: UserCardColumnNames.TEAM,
            title: this.$t('userForRate.team') as string,
            width: '5fr',
          },
          {
            name: UserCardColumnNames.ACTION,
            title: '',
            width: '5fr',
          },
        ];
      },
    },
    surveyId: {
      type: Number,
      required: true,
    },
    showAddExpertButton: {
      type: Boolean,
      default: false,
    },
    hideDeleteButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      couplesLoading: false,
      couplesData: {
        expertsLists: [] as V1EntitiesExpertListsPublicIndexExpertList[],
        couples: [] as V1EntitiesExpertListsPublicIndexCouple[],
      },
      selectedGroupId: null as number | null,
      rejectingLoading: false,
      isAddingToGroupDialogShow: false,
      addingToGroupLoading: false,
      addingExternalsLoading: false,
      iam: PostV1CouplesIam.SURVEYEE,
    };
  },

  computed: {
    ...mapState('engagementInfo', {
      user: 'user',
    }),
    groupsWithCouples(): ExpertGroupCouples[] {
      if (!this.couplesData) {
        return [];
      }

      return joinExpertListsWithCouples(this.couplesData);
    },
  },

  mounted() {
    this.loadCouples();
  },

  methods: {
    canAddExternalExperts(groupId: number): boolean {
      const selectedGroup = this.couplesData?.expertsLists?.find((list) => list.id === groupId);
      return Boolean(selectedGroup?.canAddExternalExperts);
    },
    async loadCouples() {
      try {
        this.couplesLoading = true;

        const {
          data: { expertsLists, couples },
        } = await tsxassApi.getV1SurveysSurveyIdExpertsLists(this.surveyId);
        this.couplesData = {
          expertsLists,
          couples,
        };
        this.$emit('couples-loaded', this.couplesData);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.couplesLoading = false;
      }
    },
    async deleteCouple(coupleId: number) {
      try {
        this.rejectingLoading = true;

        await tsxassApi.deleteV1CouplesCoupleId(coupleId);
        this.onChange();
        this.loadCouples();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.rejectingLoading = false;
      }
    },
    addExpert(groupId: number) {
      this.selectedGroupId = groupId;
      this.isAddingToGroupDialogShow = true;
    },
    async addUsersToGroup(userIds: UserId[]) {
      if (!this.selectedGroupId || !userIds.length) {
        return;
      }

      try {
        this.addingToGroupLoading = true;
        await tsxassApi.postV1CouplesByBatch({
          expertListId: this.selectedGroupId,
          couples: userIds.map((userId) => ({
            expertUserId: userId,
            surveyeeUserId: this.user?.userId,
          })),
        });
        this.onChange();
        this.loadCouples();
      } catch (err) {
        errorHandler(err);
      } finally {
        if (!this.addingExternalsLoading) {
          this.isAddingToGroupDialogShow = false;
        }
        this.addingToGroupLoading = false;
      }
    },
    async addExternalExperts(externalUsers: PostV1Invitees[]) {
      if (!externalUsers.length) {
        return;
      }
      try {
        this.addingExternalsLoading = true;
        await Promise.allSettled(
          externalUsers.map(async (user) => {
            await tsxassApi.postV1Invitees(user);
          }),
        );
        this.onChange();
        this.loadCouples();
      } catch (err) {
        errorHandler(err);
      } finally {
        if (!this.addingToGroupLoading) {
          this.isAddingToGroupDialogShow = false;
        }
        this.addingExternalsLoading = false;
      }
    },
    onChange() {
      this.$emit('on-change');
    },
  },
});
