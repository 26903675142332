
import Vue, { PropType } from 'vue';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import { V1EntitiesExpertListsPublicIndexCouple } from '@/services/api/tsxass';
import UserGroupTable from '@/components/common/UserGroupTable/UserGroupTable.vue';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';
import ExpertCard from './ExpertCard.vue';

export default Vue.extend({
  name: 'ExpertGroup',

  components: {
    ExpertCard,
    UserGroupTable,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    couples: {
      type: Array as PropType<V1EntitiesExpertListsPublicIndexCouple[]>,
      default: () => [],
    },
    showAddExpertButton: {
      type: Boolean,
      default: false,
    },
    canAddExternalExperts: {
      type: Boolean,
      default: false,
    },
    hideDeleteButton: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: Number,
      default: -1,
    },
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      default(): UserCardColumn[] {
        return [
          {
            name: UserCardColumnNames.EMPLOYEE,
            title: this.$t('userForRate.employee') as string,
            width: '6fr',
          },
          {
            name: UserCardColumnNames.TEAM,
            title: this.$t('userForRate.team') as string,
            width: '5fr',
          },
          {
            name: UserCardColumnNames.ACTION,
            title: '',
            width: '5fr',
          },
        ];
      },
    },
  },
});
