
import Vue, { PropType } from 'vue';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import {
  V1EntitiesExpertListsPublicIndexCouple,
  V1EntitiesAudiencesPublicSearchIndexAudience,
} from '@/services/api/tsxass';
import UserCard from '@/components/common/UserGroupTable/UserCard.vue';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';

export default Vue.extend({
  name: 'ExpertCard',

  components: {
    UserCard,
  },

  props: {
    coupleData: {
      type: Object as PropType<V1EntitiesExpertListsPublicIndexCouple>,
      required: true,
    },
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      required: true,
    },
    hideDeleteButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      UserCardColumnNames,
    };
  },

  computed: {
    user(): V1EntitiesAudiencesPublicSearchIndexAudience {
      return this.coupleData.expert;
    },
  },
});
